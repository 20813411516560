export default [
  {
    name: "description",
    required: true,
    label: "Description",
    align: "left",
    sortable: true,
    field: (i: any) => i.description
  },
  {
    name: "relatedTo",
    required: true,
    label: "Related To",
    align: "left",
    sortable: true,
    field: (i: any) => i.relatedTo
  },
  {
    name: "response",
    required: true,
    label: "Response",
    align: "left",
    sortable: true,
    field: (i: any) => i.response
  },
  {
    name: "likelihood",
    required: true,
    label: "Likelihood",
    align: "right",
    sortable: true,
    field: (i: any) => i.likelihood
  },
  {
    name: "consequence",
    required: true,
    label: "Consequence",
    align: "right",
    sortable: true,
    field: (i: any) => i.consequence
  },
  {
    name: "score",
    required: true,
    label: "Score",
    align: "right",
    sortable: true,
    field: (i: any) => i.score
  }
];
