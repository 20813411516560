



















































































import { Component, Prop, Vue } from "vue-property-decorator";
import DataProvider from "@/lib/DataProvider";
import { Project } from "@/data/models/Projects";
import ModelTable from "@/components/ModelTable.vue";
import activitiesColumns from "@/data/columns/MonitoringSession";
import MonitoringSessionForm from "@/components/forms/MonitoringSessionForm.vue";
import ImportDialog from "@/components/ImportDialog.vue";

import { saveAs } from "file-saver";
@Component({
  components: { ModelTable, MonitoringSessionForm, ImportDialog }
})
export default class ProjectMonitoringSessions extends Vue {
  @Prop()
  public id!: string;
  @Prop()
  public title!: string;

  private activitiesColumns: any[] = activitiesColumns;
  private provider: DataProvider<any> | null = null;
  private selected: any[] = [];
  private selectedItem: any = null;
  private addActivityDialog = false;
  private importDialog = false;
  private utc = new Date().toJSON().slice(0, 10);
  mounted() {
    this.initialize();
  }

  initialize() {
    this.provider = this.$service.providers.projectMonitoringSessions(this.id);
  }

  async download(sessId: string) {
    saveAs(
      await this.$service.downloadProjectMonitoringSession(this.id, sessId),
      `MonitoringSessions_${this.title}_${this.utc}.xlsx`
    );
  }

  async downloadActions() {
    saveAs(
      await this.$service.downloadProjectMonitoringActions(this.id),
      `MonitoringActions_${this.title}_${this.utc}.xlsx`
    );
  }
}
