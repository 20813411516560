























































































































import { Component, Prop, Vue } from "vue-property-decorator";
import SubArrayForm from "../SubArrayForm.vue";
import EntitySelect from "../EntitySelect.vue";
import FileInput from "../FileInput.vue";
import EnumForm from "@/components/forms/EnumForm.vue";

@Component({
  components: {
    SubArrayForm,
    EntitySelect,
    FileInput
  }
})
export default class ProjectPartnersForm extends Vue {
  @Prop()
  public projectId!: string;

  private item: any = [];
  private curentIds: any = null;
  private deliverables: any = [];
  private enumForm = EnumForm;
  private crud = this.$service.crud.projectPartners;
  async mounted() {
    this.Initialize();
  }

  async Initialize() {
       this.item = (
      await this.$service.providers
        .getProjectPartners(this.projectId)
        .fetchItemsAsync()
    ).items;
    this.curentIds = this.item.map((x: any) => x.id);
  }

    private IsValid(i: any) {
    if(i.name && i.currencyId && i.budget !== null && i.budget !== undefined) {
      return 'border-color: #00897b;';
    } else {
      return 'border-color: #d32f2f;';
    }
  }

  private async submit() {
    if (this.curentIds === null) {
      return;
    }
    try {
      if (this.item.length === 0) {
          await this.crud.deleteAsync(this.curentIds);
        
      } else {
        await this.crud.addAsync(this.item);
        this.Initialize();
      }

      this.$q.notify({
        message: "Item saved successfully!",
        color: "green-4",
        timeout: 2000
      });
    } catch (e) {
      this.$q.notify({
        message: e,
        color: "red-4"
      });
      throw e;
    }
  }
}
