





































































import { Component, Prop, Vue } from "vue-property-decorator";
import DataProvider from "@/lib/DataProvider";
import { Project } from "@/data/models/Projects";
import ModelTable from "@/components/ModelTable.vue";
import columns from "@/data/columns/Risk";
import ProjectCommunicationActivityForm from "@/components/forms/ProjectCommunicationActivityForm.vue";
import ImportDialog from "@/components/ImportDialog.vue";
import RiskForm from "@/components/forms/RiskForm.vue";

import { saveAs } from "file-saver";
@Component({
  components: {
    ModelTable,
    ProjectCommunicationActivityForm,
    ImportDialog,
    RiskForm,
  },
})
export default class RiskTable extends Vue {
  @Prop()
  public id!: string;

  private columns: any[] = columns;
  private provider: DataProvider<any> | null = null;
  private selected: any[] = [];
  private selectedItem: any = null;
  private addDialog = false;
  private importDialog = false;
  mounted() {
    this.initialize();
  }

  async initialize() {
    this.provider = this.$service.providers.riskCategoryRisks(this.id);
  }

  async download() {
    saveAs(
      await this.$service.downloadProjectCommunicationActivities(this.id),
      "data.xlsx"
    );
  }
}
