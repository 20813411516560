





















































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import DatePicker from "../DatePicker.vue";
import EnumForm from "../forms/EnumForm.vue";

import EntitySelect from "../EntitySelect.vue";
@Component({
  components: {
    FormContainer,
    Loader,
    DatePicker,
    EntitySelect,
  },
})
export default class MonitoringFindingForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  public provider!: DataProvider<any>;

  public crud!: ICrudClient<any>;
  private enumForm = EnumForm;
  private item: any = null;

  created() {
    this.provider = this.$service.providers.monitoringFindings;
    this.crud = this.$service.crud.monitoringFindings;
  }
  async mounted() {
    if (this.id === "new") {
      this.item = {
        reportingPeriodStart: null,
        reportingPeriodEnd: null,
        description: null,
        typeId: null,
        type: null,
        comment: null,
        actionTaken: null,
        ppComments: null,
        currentStatus: null,
        recommendations: null,
        projectId: this.projectId,
        findings: null,
      };
    }
  }
}
