















































































































































import { Component, Prop, Vue } from "vue-property-decorator";
import SubArrayForm from "../SubArrayForm.vue";
import EntitySelect from "../EntitySelect.vue";
import FileInput from "../FileInput.vue";
import EnumForm from "@/components/forms/EnumForm.vue";
import DatePicker from "@/components/DatePicker.vue";
import DonorForm from "@/components/forms/DonorForm.vue";
import { saveAs } from "file-saver";

@Component({
  components: {
    SubArrayForm,
    EntitySelect,
    FileInput,
    DatePicker
  }
})
export default class ExtensionsForm extends Vue {
  @Prop()
  public projectId!: string;

  @Prop()
  public projectName!: string;

  private item: any = [];
  private curentIds: any = null;
  private deliverables: any = [];
  private enumForm = EnumForm;
  private donorForm = DonorForm;
  private crud = this.$service.crud.projectExtensions;
  async mounted() {
    this.Initialize();
  }

  async Initialize() {
    this.item = (
      await this.$service.providers
        .getProjectExtensions(this.projectId)
        .fetchItemsAsync()
    ).items;
    this.curentIds = this.item.map((x: any) => x.id);
  }

  async extract() {
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(
      await this.$service.extract(
        "ProjectExtensions/Extract",
        null,
        this.projectId
      ),
      `Extensions_${this.projectName}_${utc}.xlsx`
    );
  }

  private IsValid(obj: any) {
    if (!!obj.type && !!obj.file && obj.title) {
      return "border-color: #00897b;";
    } else {
      return "border-color: #d32f2f;";
    }
  }

  private async submit() {
    if (this.curentIds === null) {
      return;
    }
    try {
      if (this.item.length === 0) {
        await this.crud.deleteAsync(this.curentIds);
      } else {
        await this.crud.addAsync(this.item);
        this.Initialize();
      }

      this.$q.notify({
        message: "Item saved successfully!",
        color: "green-4",
        timeout: 2000
      });
    } catch (e) {
      this.$q.notify({
        message: e,
        color: "red-4"
      });
      throw e;
    }
  }
}
