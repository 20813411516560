


































































import { Component, Prop, Vue } from "vue-property-decorator";
import ModelTable from "@/components/ModelTable.vue";
import MrrForm from "@/components/forms/MrrForm.vue";
import columns from "@/data/columns/MrrSubmissions";
import DataProvider from "@/lib/DataProvider";
import { saveAs } from "file-saver";
@Component({
  components: {
    ModelTable,
    MrrForm
  }
})
export default class MrrSubmissionTable extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  @Prop()
  public name!: string;

  @Prop()
  public programmeId!: string;

  @Prop()
  public provider!: DataProvider<any>;

  private columns = columns;
  private editDialog = false;
  private selectedid = "";

  async download(mrrId: string) {
    const utc = new Date().toJSON().slice(0, 10);
    saveAs(
      await this.$service.downloadMrrQuestionnaire(this.id, mrrId),
      `MRR_${this.name}_${utc}.xlsx`
    );
  }
}
