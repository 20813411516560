export default [
  {
    name: "ReportingPeriodStart",
    required: true,
    label: "Reporting Period Start",
    align: "left",
    sortable: true,
    field: (i: any) => i.reportingPeriodStart ? new Date(Date.parse(i.reportingPeriodStart)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "ReportingPeriodEnd",
    required: true,
    label: "Reporting Period End",
    align: "left",
    sortable: true,
    field: (i: any) => i.reportingPeriodEnd ? new Date(Date.parse(i.reportingPeriodEnd)) : undefined,
    format: (val: Date) => (val ? val.toLocaleDateString() : "")
  },
  {
    name: "Description",
    required: true,
    label: "Description of monitoring activity",
    align: "left",
    sortable: true,
    field: (i: any) => i.description
  },
  {
    name: "Type/Value",
    required: true,
    label: "Type of finding",
    align: "left",
    sortable: true,
    field: (i: any) => i.type.value
  },
  {
    name: "Recommendations",
    required: true,
    label: "Recommendations",
    align: "left",
    sortable: true,
    field: (i: any) => i.recommendations
  },
  {
    name: "PpComments",
    required: true,
    label: "PP Comments",
    align: "left",
    sortable: true,
    field: (i: any) => i.ppComments
  },
  {
    name: "ActionTaken",
    required: true,
    label: "Action Taken",
    align: "left",
    sortable: true,
    field: (i: any) => i.actionTaken
  },
  {
    name: "CurrentStatus",
    required: true,
    label: "Current Status",
    align: "left",
    sortable: true,
    field: (i: any) => i.currentStatus
  }
];
