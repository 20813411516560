



































































































































































































































































































































































































































































































































































































































































































































































































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import draggable from "vuedraggable";
import { Programme } from "@/data/models/Programs";
import SubArrayForm from "@/components/SubArrayForm.vue";
import { Project } from "@/data/models/Projects";
import { Guid } from "guid-typescript";
import DatePicker from "@/components/DatePicker.vue";
import { readFileAsDataUrlAsync } from "@/lib/readFile";
import { Questionnaire } from "@/data/models/Questionnaires";
import EntitySelect from "@/components/EntitySelect.vue";
import EnumForm from "@/components/forms/EnumForm.vue";
import FileInput from "@/components/FileInput.vue";
import ProjectCommunicationActivities from "@/components/ProjectCommunicationActivities.vue";
import DonorForm from "@/components/forms/DonorForm.vue";
import ProjectMonitoringFindings from "@/components/ProjectMonitoringFindings.vue";
import RfFilesTable from "@/components/RFFilesTable.vue";
import ProjectMonitoringSessions from "@/components/ProjectMonitoringSessions.vue";
import MeetingsTable from "@/components/MeetingsTable.vue";
import IndicatorsTable from "@/components/IndicatorsTable.vue";
import ProjectRiskAssessment from "@/components/ProjectRiskAssessment.vue";
import ConsentForm from "./ConsentForm.vue";
import InMemoryDataProvider from "@/lib/InMemory/InMemoryDataProvider";
import DynamicForm from "@/components/DynamicForm.vue";
import MrrTable from "@/components/MrrTable.vue";
import ProjectPartnersForm from "@/components/forms/ProjectPartnersForm.vue";
import DeliverablesForm from "@/components/forms/DeliverablesForm.vue";
import ExtensionsForm from "@/components/forms/ExtensionsForm.vue";

@Component({
  components: {
    FormContainer,
    Loader,
    draggable,
    DatePicker,
    SubArrayForm,
    EntitySelect,
    FileInput,
    ProjectCommunicationActivities,
    ProjectRiskAssessment,
    ProjectMonitoringFindings,
    ProjectMonitoringSessions,
    MeetingsTable,
    IndicatorsTable,
    DynamicForm,
    MrrTable,
    RfFilesTable,
    ProjectPartnersForm,
    DeliverablesForm,
    ExtensionsForm
  }
})
export default class ProjectsForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public provider!: DataProvider<Project>;

  @Prop()
  public crud!: ICrudClient<Project>;

  private donorForm = DonorForm;
  private enumForm = EnumForm;
  private item: any = null; // Project | null = null;
  private donations: any[] | null = null;
  private users: any[] | null = null;
  private activityRecords: any[] | null = null;
  private indicators: any[] | null = null;
  private callForTenders = null;
  private tab = "data";
  private financialReports = [];
  private narrativeReports = [];
  private projecManagerFilter = encodeURI("contains(UserGroup/Permissions, 'admin\":{\"is\":true}') or contains(UserGroup/Permissions, 'projectManager\":{\"is\":true}')");
  private empty = new InMemoryDataProvider<any>([]);
  private async setFile(file: File, key: string) {
    if (this.item) {
      const res = await readFileAsDataUrlAsync(file);
      this.item[key] = {
        name: file.name,
        base64Contents: res
      };
    }
  }

  formTitle() {
    if (this.id == "new") {
      return `Add ${this.item?.programme?.type ?? ""} Project`;
    }
    return `Edit ${this.item?.programme?.type ?? ""} Project`;
  }

  @Watch("item.programmeId", { immediate: true })
  async programmeChanged(val: any) {
    if (val) {
      const pr = await this.$service.providers.programs.fetchItemAsync(val);
      this.donations = pr.donations;
      this.indicators = pr.indicators;
    }
    if (this.item) {
      this.item.isCaseManagement = this.item.isCaseManagement
        ? this.item.isCaseManagement
        : false;
      if (this.item.programme && this.item.programme.type !== "Regranting") {
        this.item.type = null;
      }
    }
  }

  private extensionsProvider: DataProvider<any> | null = null;
  private partnersProvider: DataProvider<any> | null = null;
  private deliverablesProvider: DataProvider<any> | null = null;

  @Watch("item", { deep: false, immediate: false })
  async onItemChanged(oldv: any, newv: any) {
    if (!this.item) {
      return;
    }

    this.extensionsProvider = new InMemoryDataProvider(
      this.item.projectExtensions
    );
    this.partnersProvider = new InMemoryDataProvider(this.item.projectPartners);
    this.deliverablesProvider = new InMemoryDataProvider(
      this.item.deliverables
    );

    if (this.item.projectExtension === null) {
      this.item.projectExtension = {
        startDate: null,
        endDate: null,
        type: null,
        donations: [],
        budget: 0,
        donor: null,
        currency: null
      };
    }

    const a = oldv === newv;

    if (this.item.financialReports != null) {
      this.financialReports = this.item.financialReports.map((a: any) => {
        return { file: { id: a.id, name: a.name } };
      });
    }
    if (this.item.narrativeReports != null) {
      this.narrativeReports = this.item.narrativeReports.map((a: any) => {
        return { file: { id: a.id, name: a.name } };
      });
    }
  }

  async submit(v: any) {
    try {
      const itemToSubmit = JSON.parse(JSON.stringify(this.item));
      itemToSubmit.financialReports = this.financialReports.map(
        (a: any) => a.file
      );
      itemToSubmit.narrativeReports = this.narrativeReports.map(
        (a: any) => a.file
      );
      if (this.id == "new") {
        const res = await this.crud.addAsync([itemToSubmit]);

        this.$q.notify({
          message: "Item saved successfully!",
          color: "green-4",
          timeout: 2000
        });

        this.item = res[0];
        return res[0];
      } else {
        delete itemToSubmit.communicationType;
        const res = await this.crud.saveAsync(itemToSubmit);

        this.$q.notify({
          message: "Item saved successfully!",
          color: "green-4",
          timeout: 2000
        });

        return res;
      }
    } catch (e) {
      console.error(e);
      this.$q.notify({
        message: e,
        color: "red-4"
      });
      throw e;
    } finally {
      this.item = await this.$service.providers.projects.fetchItemAsync(
        this.item.id
      );
    }
  }

  async mounted() {
    if (this.id === "new") {
      this.item = {
        id: Guid.createEmpty().toString(),
        title: "",
        programmeId: "",
        roleInPartnership: null,
        roleInPartnershipId: null,
        contract: null,
        callForTenders: null,
        consentFormId: "",
        projectManagers: null,
        includePersonalDevelopmentPlan: false,
        projectActivities: [],
        type: null,
        isCaseManagement: false,
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        fundAmount: 0,
        indicators: [],
        deliverables: [],
        financialReports: [],
        narrativeReports: [],
        riskAssessment: null,
        projectExtension: {
          startDate: null,
          endDate: null,
          type: null,
          donors: [],
          budget: 0
        }
      };
    }
  }

  handleAssessment(has: boolean) {
    if (has) {
      this.item.riskAssessment = {
        id: this.item.id,
        consequence: "",
        likelihood: "",
        categories: []
      };
    } else {
      this.item.riskAssessment = null;
    }
  }
}
