






















































































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import EnumForm from "@/components/forms/EnumForm.vue";
import EntitySelect from "@/components/EntitySelect.vue";

@Component({
  components: {
    FormContainer,
    Loader,
    EntitySelect
  },
})
export default class RiskForm extends Vue {
  private enumForm = EnumForm;

  @Prop()
  public id!: string;

  @Prop()
  public categoryId!: string;

  private provider: DataProvider<any> | null = null;

  private crud: ICrudClient<any> | null = null;

  private item: any = null;

  created() {
    this.provider = this.$service.providers.risks;
    this.crud = this.$service.crud.risks;
  }

 @Watch("item.score", { immediate: false })
   CalculateScore() {
    this.item.score = Math.round(Math.sqrt(
      this.item.likelihood *
      this.item.consequence))
  }

     @Watch("item.likelihood",  { immediate: true })
  onLikelihoodChanged() {
    if (!this.item) {
      return;
    }
     this.item.score = this.CalculateScore;
  }
 
    @Watch("item.consequence",  { immediate: true })
  onConsequenceChanged() {
    if (!this.item) {
      return;
    }
     this.item.score = this.CalculateScore;
  }

  mounted() {
    if (this.id === "new") {
      this.item = {
        description: "",
        relatedTo: "",
        response: "",
        responseDescription: "",
        likelihood: 0,
        consequence: 0,
        score: 0,
        categoryId: this.categoryId,
      };
    }
  }
}
