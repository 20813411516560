
























import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import EnumForm from "@/components/forms/EnumForm.vue";

@Component({
  components: {
    FormContainer,
    Loader,
  }
})
export default class RiskCategoryForm extends Vue {
  private enumForm = EnumForm;

  @Prop()
  public id!: string;

  @Prop()
  public assessmentId!: string;

  private provider: DataProvider<any> | null = null;

  private crud: ICrudClient<any> | null = null;

  private item: any = null;

  created() {
    this.provider = this.$service.providers.riskCategories;
    this.crud = this.$service.crud.riskCategories;
  }
  mounted() {
    if (this.id === "new") {
      this.item = {
        name: "",
        assessmentId: this.assessmentId,
      };
    }
  }

}
