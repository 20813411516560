





































import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import Loader from "../Loader.vue";
import DatePicker from "../DatePicker.vue";
import EnumForm from "./EnumForm.vue";
import DynamicForm from "../DynamicForm.vue";

import EntitySelect from "../EntitySelect.vue";
import { toArray, toMap } from "@/lib/answers";
import { NewQuestionnaireSubmission } from "@/data/models/Questionnaires";
@Component({
  components: {
    FormContainer,
    Loader,
    DatePicker,
    EntitySelect,
    DynamicForm,
  },
})
export default class MonitoringSessionForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  public provider!: DataProvider<any>;
  private project: any = null;
  public crud!: ICrudClient<any>;
  private enumForm = EnumForm;
  private item: any = null;
  private questionnaire: any = null;
  created() {
    this.provider = this.$service.providers.monitoringSessions;
    this.crud = this.$service.crud.monitoringSessions;
  }
  async mounted() {
    if (this.id === "new") {
      this.item = {
        date: null,
        promoter: null,
        projectId: this.projectId,
        submission: {
          answers: [],
        },
      };
    }
    this.project = await this.$service.providers.projects.fetchItemAsync(
      this.projectId
    );
    this.questionnaire =
      await this.$service.providers.questionnaires.fetchItemAsync(
        this.project.monitoringForm.id
      );
    this.item.submission.questionnaireId = this.questionnaire.id;
  }
}
