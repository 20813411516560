var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('q-card-section',{staticClass:"title text-h6"},[_vm._v("Add/Edit Risk Assessment")]),_c('q-separator'),_c('model-table',{ref:"table",attrs:{"flat":"","provider":_vm.provider,"crud":_vm.$service.crud.riskCategories,"selection":'multiple',"getName":function (i) { return i.name; },"columns":_vm.columns,"selected":_vm.selected,"viewPermission":function (r) { return true; },"deletePermission":function (r) { return true; },"hideTop":true,"allowExpand":true},on:{"update:selected":function($event){_vm.selected=$event}},scopedSlots:_vm._u([{key:"expand-button-content",fn:function(ref){
var scope = ref.scope;
return [_c('q-badge',{attrs:{"color":"orange","floating":"","transparent":""}},[_vm._v(" "+_vm._s(scope.row["risks@odata.count"])+" ")]),_c('q-tooltip',[_vm._v(" View Requests ")])]}},{key:"title",fn:function(){return [_c('q-btn',{attrs:{"color":"green-5","icon":"add","label":"Add Risk","no-caps":""},on:{"click":function($event){_vm.selectedItem = { id: 'new' };
          _vm.addDialog = true;}}}),_c('q-btn',{attrs:{"icon":"download","label":"Download","flat":"","no-caps":"","loading":_vm.$proxy.downloading},on:{"click":function($event){return _vm.$runAsync('downloading', _vm.download)}}}),_c('q-btn',{attrs:{"icon":"upload","label":"Upload","flat":"","no-caps":""},on:{"click":function($event){_vm.importDialog = true}}})]},proxy:true},{key:"row-actions",fn:function(ref){
          var scope = ref.scope;
return [_c('q-btn',{attrs:{"icon":"edit","flat":"","no-caps":""},on:{"click":function($event){_vm.selectedItem = scope.row;
          _vm.addDialog = true;}}})]}},{key:"expanded",fn:function(ref){
          var scope = ref.scope;
return [_c('risk-table',{attrs:{"id":scope.row.id}})]}}])}),_c('q-dialog',{model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[(_vm.selectedItem)?_c('risk-category-form',{attrs:{"id":_vm.selectedItem.id,"assessmentId":_vm.id,"inDialog":true},on:{"save":function($event){_vm.addDialog = false;
        _vm.initialize();}}}):_vm._e()],1),_c('import-dialog',{attrs:{"uploadFunction":function (file) { return _vm.$service.uploadRiskAssessment(file, _vm.id); }},on:{"save":_vm.initialize},model:{value:(_vm.importDialog),callback:function ($$v) {_vm.importDialog=$$v},expression:"importDialog"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }