














































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import FormContainer from "../FormContainer.vue";
import DynamicForm from "./../DynamicForm.vue";
import Loader from "@/components/Loader.vue";
import ICrudClient from "@/lib/ICrudClient";
import DataProvider from "@/lib/DataProvider";
import DatePicker from "@/components/DatePicker.vue";
import { NewQuestionnaireSubmission } from "@/data/models/Questionnaires";

@Component({
  components: {
    DynamicForm,
    Loader,
    FormContainer,
    DatePicker,
  },
})
export default class MrrForm extends Vue {
  @Prop()
  public id!: string;

  @Prop()
  public projectId!: string;

  public provider!: DataProvider<any>;
  public crud!: ICrudClient<any>;
  questionnaire: any = null;
  project: any = null;
  private answers: [] = [];

  private item: any = null;

  async created() {
    this.item = {
      projectId: this.projectId,
      questionnaireSubmission: null,
      mrrPeriod: {
        periodName: null,
        periodStart: null,
        periodEnd: null,
      },
    };

    this.project = (await this.$service.providers.projects.fetchItemAsync(
      this.projectId
    )) as any;
    this.questionnaire =
      await this.$service.providers.questionnaires.fetchItemAsync(
        this.project.mrrId
      );
  }

  async Initialize() {
    if (this.id !== "new") {
      const questionnaireSubmission =
        await this.$service.providers.mrrSubmissions.fetchItemAsync(this.id)
      this.item.id = this.id
      this.item.questionnaireSubmission = questionnaireSubmission.questionnaireSubmission;
      this.item.mrrPeriod = questionnaireSubmission.mrrPeriod;
      this.answers = questionnaireSubmission.questionnaireSubmission.answers;
    }
  }

  @Watch("item")
  async onItemChanged() {
    this.Initialize();
  }

  async submit() {
    try {
      if (!this.item || this.id === "new") {
        this.item.questionnaireSubmission = NewQuestionnaireSubmission(
          this.project.mrrId,
          this.answers
        );
        await this.$service.crud.mrrSubmissions.addAsync([this.item]);
      } else {
        this.item.questionnaireSubmission.answers = this.answers;
        await this.$service.crud.mrrSubmissions.saveAsync(this.item);
      }
    } catch (err) {
      this.$q.notify({ message: err, color: "red-4" });
      console.error(err);
      throw err;
    } finally {
      //this.submittingPrescreening = false;
    }
  }
}
